import DynamikOverview from '../../dynamikModul/Modules/Pages/overview'
import React from 'react'
import {
    ResponseOverview,
    ResponseDelete,
    ResponseDetail,
    ResponseReferalNumber,
    ResponseUserFiles
} from './API/response/Type/customizer'
import DynamikDeleted from '../../dynamikModul/Modules/Pages/deleted'
import DynamikDetail from '../../dynamikModul/Modules/Pages/detail'
import DynamikNew from '../../dynamikModul/Modules/Pages/new'
import CardJSX from './configuration/card'
import SearchConfig from './configuration/SearchConfig'
import { destination, link } from './helper/const'
import table from './configuration/table'
import Forms from './configuration/Form'
import {jsonMapping, jsonMappingEdit} from "./configuration/json.mapping";

export const CustomerPagesConfig = (value: any) => {
    return [
        {
            path: '/customer/overview/:page',
            roles: ['superadmin', 'admin'],
            elements: (
                <DynamikOverview
                    value={value}
                    table={table}
                    destination={destination}
                    state={'customer'}
                    SearchConfig={SearchConfig(destination)}
                    CardsConfiguration={CardJSX(link, destination)}
                    response={ResponseOverview}
                    dynamik={false}
                    buttonText={'Mandant '}
                />
            )
        },
        {
            path: '/customer/Delete/:page/:deleteID',
            elements: (
                <DynamikDeleted
                    value={value}
                    reponse={ResponseDelete}
                    namespace={destination}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/customer/new/new',
            elements: (
                <DynamikNew
                    value={value}
                    forms={Forms}
                    addedForm={ResponseReferalNumber}
                    namespace={'/' + destination + '/overview/1'}
                    saveRoute={'customer/0/'}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/customer/new/:page',
            elements: (
                <DynamikNew
                    value={value}
                    forms={Forms}
                    Json_mapping={true}
                    Mapping_Data = {jsonMapping}
                    addedForm={ResponseReferalNumber}
                    namespace={'/' + destination + '/overview/1'}
                    saveRoute={'customer/0/'}
                />
            ),
            roles: ['superadmin', 'admin']
        },
        {
            path: '/customer/Detail/:id',
            elements: (
                <DynamikDetail
                    value={value}
                    forms={Forms}
                    Json_mapping={true}
                    Mapping_Data = {jsonMappingEdit}
                    addedForm={ResponseReferalNumber}
                    addedForm2={ResponseUserFiles}
                    paramsAddedForm2={true}
                    paramsAddedForm={true}
                    response={ResponseDetail}
                    saveRoute={'customer/'}
                />
            ),
            roles: ['superadmin', 'admin']
        }
    ]
}

export const CustomerMenuConfig = (value: any) => {
    return [
        {
            name: 'Mandanten',
            route: '/customer/overview/1',
            icon: 'manage_accounts',
            roles: ['superadmin', 'admin']
        }
    ]
}

export const CustomerDasboard = () => [
    {
        title: 'Mandanten',
        href: '/customer/overview/1',
        undertitle: 'Backoffice',
        icon: 'manage_accounts',
        text: 'Mandanten',
        roles: ['superadmin', 'admin']
    }
]
