

import React from 'react'
import SunEditorBig from "../../component/const/SunEditorBig";
import {SunEditorConfig} from "../../component/const/SunEditorConfig";
import PielersForm from '../../form-serilizer/index'

function LoopSunEditor(data: any, i: any) : any {

    let SunHolder : any = [];

    const LoopSun = (response: any, index: number) => {

        return SunEditorBig(
            response,
            data.keyEditor,
            data.newElement,
            { lang: 'de', height: '350px;', config: SunEditorConfig() },
            data.keyEditor,
            data.label + index + " :",
            '',
            '',
            '',
            '',
            'classic',
            '200'
        )
    }
    data.value[data.valueKey].map((response:any, i:any) => {
        if(data.func !== undefined && data.func !== null) {
            SunHolder.push(data.func(response, i));
        } else {
            SunHolder.push(LoopSun(response, i));
        }

    });
    return (

        <>
            {PielersForm(
                SunHolder
            )}
        </>
    )
}

export default LoopSunEditor
