import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Form from 'react-bootstrap/Form'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup'

const Forms = (
    response: any = [],
    mode: any = 'new',
    fetch: any = [],
    fetch2: any = []
) => {
    return (
        <>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                key={'tabindex'}
            >
                <Tab
                    eventKey="home"
                    title={'Allgemein'}
                    key={'allgemein'}
                    id={'allgemein'}
                >
                    {(mode === 'new' ? <></> :<>
                    <Form.Control
                        type="hidden"
                        name={'customer_id'}
                        defaultValue={
                            mode === 'new'
                                ? ''
                                : response[0].customer.id
                        }
                    />
                    <Form.Control
                        type="hidden"
                        name={'address_id'}
                        defaultValue={
                            mode === 'new'
                                ? ''
                                : response[0].address[0].id
                        }
                    />

                    <Form.Control
                        type="hidden"
                        name={'userId'}
                        defaultValue={
                            mode === 'new'
                                ? ''
                                : response[0].id
                        }
                    />
                    </>)}
                    <h3>Allgemein</h3>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>Mandant:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'username'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].username
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="is_active">
                                <Form.Label>Status:</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    name={'is_active'}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].is_active
                                    }
                                >
                                    <option value={1}>Aktiviert</option>
                                    <option value={0}>Deaktiviert</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h3>Mandantendaten</h3>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="firstName">
                                <Form.Label>Vorname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'firstName'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].customer.firstName
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="lastName">
                                <Form.Label>Nachname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'lastName'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].customer.lastName
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>E-Mail:</Form.Label>
                                <Form.Control
                                    type="email"
                                    name={'email'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].customer.email
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="phoneNumber"
                            >
                                <Form.Label>Telefonnummer:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'phoneNumber'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].customer.phoneNumber
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <h3>Adressdaten</h3>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="company">
                                <Form.Label>Firmenname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'company'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address[0].company
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="street">
                                <Form.Label>Straße:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'street'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address[0].street
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="postcode">
                                <Form.Label>Postleitzahl:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'postcode'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address[0].postcode
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>Stadt:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'city'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address[0].city
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="country_code"
                            >
                                <Form.Label>Ländercode:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'country_code'}
                                    required={true}
                                    pattern="[A-Za-z]{2}" title="2 Stelliger Ländercode. Hier eine Liste https://de.wikipedia.org/wiki/ISO-3166-1-Kodierliste "
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].address[0].country_code
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="token">
                                <Form.Label>ReferalNumber:</Form.Label>
                                <Form.Control
                                    type="text"
                                    onClick={(e) => confirmAction(e)}
                                    readOnly={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : fetch[0] !== undefined &&
                                              fetch[0] !== null
                                            ? fetch[0].token
                                            : ''
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="provision">
                                <Form.Label>Provision:</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                        %
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        name={'provision'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : response[0].provision
                                        }
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Tab>
                <Tab
                    eventKey="bill"
                    title={'Rechnungsdaten'}
                    key={'bill'}
                    id={'bill'}
                >
                    <h2>Rechnungsadressen</h2>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="company">
                                <Form.Label>Firmenname:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'billing_company'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : (response[0].billingsInformation !== null ? response[0].billingsInformation.company : '')
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="street">
                                <Form.Label>Straße:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'billing_street'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : (response[0].billingsInformation !== null ? response[0].billingsInformation.street : '')
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="postcode">
                                <Form.Label>Postleitzahl:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'billing_postcode'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : (response[0].billingsInformation !== null ? response[0].billingsInformation.postcode : '')
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>Stadt:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'billing_city'}
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : (response[0].billingsInformation !== null ? response[0].billingsInformation.city : '')
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="country_code"
                            >
                                <Form.Label>Ländercode:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'billing_country_code'}
                                    pattern="[A-Za-z]{2}" title="2 Stelliger Ländercode. Hier eine Liste https://de.wikipedia.org/wiki/ISO-3166-1-Kodierliste "
                                    required={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : (response[0].billingsInformation !== null ? response[0].billingsInformation.country_code : '')
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <h2>Kontodetails</h2>
                    <Form.Group className="mb-3" controlId="Kontoinhaber">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                Inhaber:
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                name={'Kontoinhaber'}
                                required={true}
                                defaultValue={
                                    mode === 'new'
                                        ? ''
                                        : (response[0].billingsInformation !== null ? response[0].billingsInformation.kontoinhaber : '')
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="provision">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                        IBAN:
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name={'IBAN'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : (response[0].billingsInformation !== null ? response[0].billingsInformation.IBAN : '')
                                        }
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="provision">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">
                                        BIC:
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name={'BIC'}
                                        defaultValue={
                                            mode === 'new'
                                                ? ''
                                                : (response[0].billingsInformation !== null ? response[0].billingsInformation.BIC : '')
                                        }
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Tab>
                <Tab
                    eventKey="german"
                    title={'Deutsche Unternehmen'}
                    key={'german'}
                    id={'german'}
                >
                    {buildFilesbyBusiness(fetch2, 'german')}
                </Tab>
                <Tab
                    eventKey="foreign"
                    title={'Ausländische Unternehmen'}
                    key={'foreign'}
                    id={'foreign'}
                >
                    {buildFilesbyBusiness(fetch2, 'foreign')}
                </Tab>
                <Tab eventKey="sro" title={'s.r.o'} key={'sro'} id={'sro'}>
                    {buildFilesbyBusiness(fetch2, 'sro')}
                </Tab>
                <Tab eventKey="ewiv" title={'ewiv'} key={'ewiv'} id={'ewiv'}>
                    {buildFilesbyBusiness(fetch2, 'ewiv')}
                </Tab>
                <Tab
                    eventKey="stiftungen"
                    title={'Stiftungen'}
                    key={'stiftungen'}
                    id={'stiftungen'}
                ></Tab>
            </Tabs>
        </>
    )
}

export default Forms

const buildFilesbyBusiness = (business: any, key: any) => {
    let companyHolder = []
    for (let b = 0; b < business.length; b++) {
        if (key === business[b].type) {
            companyHolder.push(
                <>
                    <Col className={'col-4'}>
                        <Card style={{ width: '400', height: '550' }}>
                            <Card.Body>
                                <Card.Title>
                                    {business[b].companyName}
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Hinterlegtes Dokument
                                </Card.Subtitle>
                                <Card.Text className={'text-center'}>
                                    {business[b].media.content_type.includes(
                                        'image'
                                    ) ? (
                                        <Image
                                            src={business[b].media.cdn_url}
                                            style={{ width: 'fit-content' }}
                                        />
                                    ) : (
                                        <object
                                            data={business[b].media.cdn_url}
                                            width={'300px'}
                                            height={'400px'}
                                            aria-label={'pdf'}
                                            className={'mb-3'}
                                        />
                                    )}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">
                                    {business[b].docType}: {business[b].name}
                                </small>
                            </Card.Footer>
                        </Card>
                        <br />
                    </Col>
                </>
            )
        }
    }
    return (
        <>
            <Row>{companyHolder}</Row>
        </>
    )
}

const confirmAction = (e: any) => {
    if ((e.target as HTMLInputElement).readOnly === true) {
        const response = window.confirm(
            'Sind Sie sicher, dass Sie die Partnernummer ändern wollen. Das hat große Auswirkungen!'
        )

        if (response) {
            alert('Änderung durchgeführt auf Anfrage des Nutzer.')
            ;(e.target as HTMLInputElement).readOnly = false
        } else {
            alert('Änderung abgebrochen durch Nutzer.')
        }
    }
}
