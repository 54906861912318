import React from 'react'
import { ResponseMerchant } from '../../../API/response/orders/orders'
import PielersTable from '../../../../../../@WUM/core/tablemaker'
import { SpinnerJSX } from '../../../../../../@WUM/core/component/const/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { handleSucces } from '../../../../../../@WUM/core/component/const/HandleSucces'
import PaginationJSX from '../../../../../../@WUM/core/form-serilizer/elements/pagination'
import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage'
import { DummyUser } from '../../../../../../@WUM/core/dummy/user'
import { useSelector } from 'react-redux'

interface NotificationsProps {
    value: any
}

const OrdersMerchantoverview: React.FC<NotificationsProps> = (value: any) => {
    const user = DummyUser()

    const Store = useSelector((state: any) => state)
    const forbidden = (search: any) => {
        if (user !== null) {
            for (let u = 0; u < user.role.length; u++) {
                if (user.role[u] === search) {
                    return true
                } else {
                }
            }
        }
    }
    const { page } = useParams<{ page: string }>()
    let limit: any
    let confimID
    if (
        user !== null &&
        user !== undefined &&
        (forbidden('merchant') === true || forbidden('merchant_ma') === true)
    ) {
        if (user.merchant_id !== null) {
            confimID = user.merchant_id
        } else {
            confimID = '0'
        }
    } else {
        confimID = '0'
    }
    if (typeof page === 'string') {
        limit = (parseFloat(page) - 1) * 10
    }

    const response = ResponseMerchant(confimID, limit)
    const history = useNavigate()
    const destination = 'Orders/Merchant'

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: [
                'checkout_completed_at',
                'number',
                'state',
                'shipping_state',
                'paymentDetail.name',
                'payment_state',
                'coupon_amount',
                'customer.name',
                'payment.amount'
            ],
            type: [
                'dateCut',
                'string',
                'orderstate',
                'shipping_state',
                'string',
                'payment_state',
                'coupon',
                'costumer',
                'summe'
            ]
        },
        tableHeader: [
            <IntlMessage Store={Store} messageId="order.date" />,
            <IntlMessage Store={Store} messageId="order.no" />,
            <IntlMessage Store={Store} messageId="common.status" />,
            <IntlMessage Store={Store} messageId="delivery.status" />,
            <IntlMessage Store={Store} messageId="payment.method" />,
            <IntlMessage Store={Store} messageId="payment.status" />,
            <IntlMessage Store={Store} messageId="discount" />,
            <IntlMessage Store={Store} messageId="common.customer" />,
            <IntlMessage Store={Store} messageId="common.price.total" />
        ],
        tableHeaderWidth: [
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '20%',
            '10%'
        ],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menuItem: [<IntlMessage Store={Store} messageId="display.order" />],
            menuroute: ['/Orders/Merchant/Detail'],
            menuParams: [false, 'id', 'id'],
            menuIcons: ['eye']
        }
    }
    if (response.length > 0) {
        return (
            <>
                <div className={'site'}>
                    <PielersTable Configuration={Configuration} />
                    <PaginationJSX
                        response={response}
                        history={history}
                        to={'overview'}
                        handleSuccess={handleSucces}
                        destination={destination}
                    />
                </div>
            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default OrdersMerchantoverview
/*function show () {

  const spinnerElement = document.getElementsByClassName('spinner');
  const siteElement = document.getElementsByClassName('site');
  spinnerElement[0].setAttribute('style','display:none');
  siteElement[0].setAttribute('style','display:block');
}*/
